<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5446 23C27.2943 23 27.9541 22.59 28.2939 21.97L31.8726 15.48C32.2425 14.82 31.7627 14 31.0029 14H16.2084L15.2688 12H12V14H13.9993L17.5979 21.59L16.2484 24.03C15.5187 25.37 16.4783 27 17.9978 27H29.9933V25H17.9978L19.0974 23H26.5446ZM17.1581 16H29.3036L26.5446 21H19.5272L17.1581 16ZM17.9978 28C16.8982 28 16.0085 28.9 16.0085 30C16.0085 31.1 16.8982 32 17.9978 32C19.0974 32 19.997 31.1 19.997 30C19.997 28.9 19.0974 28 17.9978 28ZM27.9941 28C26.8945 28 26.0048 28.9 26.0048 30C26.0048 31.1 26.8945 32 27.9941 32C29.0937 32 29.9933 31.1 29.9933 30C29.9933 28.9 29.0937 28 27.9941 28Z"
      fill="#3C464D"
    />
    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#3C464D" />
  </svg>
</template>
